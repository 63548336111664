/** @jsx jsx */

import React from 'react'; //eslint-disable-line no-unused-vars
import { graphql } from 'gatsby';
import { Styled, jsx, Box, Grid, Flex } from "theme-ui"
import styled from '@emotion/styled'


import Layout from "../components/layout"
import Button from '../components/button'

import SEO from "../components/seo"

import ImageGallery from 'react-image-gallery'
import "react-image-gallery/styles/css/image-gallery.css";

import Seperator from '../components/Separator'

import FeatureIcons from '../components/FeatureIcons'
import Carousel from '../components/carousel'

import { Container } from '../components/item'

const StyledFlex = styled(Flex)`
  .image-gallery-thumbnails-wrapper {
    margin-top: 50px;
  }

  .image-gallery-thumbnail {
    margin: 5px;
  }

  .image-gallery-thumbnail.active {
    border: 4px solid black;
  }

  .image-gallery-thumbnail:hover {
    border: 4px solid #F7941D;
  }

`

const ProductPageTemplate = ({data}) => {

  const { shopifyProduct: { title, description, descriptionHtml, images },
          shopifyunstableapi: {
            productByHandle: { col_1, col_2, col_3, seo }
          },
        } = data;

  console.log(seo);

  const seoTitle = seo.title || title;
  const seoDescription = seo.description || description;

  const imageList = Array.from(images, image => {
    return {
      original: image.localFile.childImageSharp.full.src, 
      thumbnail: image.localFile.childImageSharp.fixed.src
    }
  });

  const slideData = 
  [
    {
      "title": "Welding Blankets",
      "text": "Welders from every industry need the same tools to stay safe. Our welding blankets offer protection for anyone working around flames, sparks, and high heat",
      "link": "/use-cases/welding-blanket-industry-uses",
      "image": data.weldingBlanketSlide.childImageSharp.fluid,
    },
    {
      "title": "Personal Protection Equipment (PPE)",
      "text": "Whether you’re at home or in a high-heat workshop, you need personal protection equipment. Reduce your personal exposure to hazards and work safely around flames and high heat.",
      "link": "/use-cases/personal-protection-equipment-ppe",
      "image": data.personalProtectionSlide.childImageSharp.fluid,
    },
    {
      "title": "Heat and Sound Barrier",
      "text": "Everywhere from under vehicle hoods, vehicle floors, generator housings, and steam pipes to workshop walls, create a buffer for safety and comfort. Draped or installed, our barrier blankets are useful wherever you need thermal and sound control.",
      "link": "/use-cases/heat-and-sound-barrier",
      "image": data.heatSoundSlide.childImageSharp.fluid,
    },
  ];

  return (
    <Layout reverseNav>

      <SEO
        title={seoTitle}
        description={seoDescription}
      />

      <Box as="section">
        <Container>
          <Flex pt='180px' pb={13} sx={{width: '100%', color: 'black', flexDirection: ['column', 'row'] }}>
            <Flex sx={{flex: '1 50%', flexDirection: 'column', alignItems: 'center'}}>
              <StyledFlex sx={{maxWidth: '400px'}}>
                <ImageGallery
                  items={imageList} 
                  showFullscreenButton={false}
                  showPlayButton={false}
                  showNav={false}
                  lazyLoad={true}
                  />
              </StyledFlex>
            </Flex>
            <Box sx={{flex: '1 50%', marginTop: ['40px', 0]}}>
              <Styled.h2 as="h1">{title}</Styled.h2>
              <Styled.p sx={{variant: 'text.body'}} dangerouslySetInnerHTML={{
                __html: descriptionHtml,
              }}></Styled.p>
              <Button to="/contact" text='Contact Us' />
            </Box>
          </Flex>
        </Container>
      </Box>

      <Seperator />
      

      <Box as="section">
        <FeatureIcons />
      </Box>

      <Box as='section' color='white' bg='red'>
        <Container py={13}>
          <Grid columns={[1, 3]} gap={[8, 8, 14]}>

            { col_1 != null &&
              <Box>
                <div dangerouslySetInnerHTML={{__html: col_1.value}} />
              </Box>
            }
            { col_2 != null &&
              <Box>
                <div dangerouslySetInnerHTML={{__html: col_2.value}} />
              </Box>
            }
            { col_3 != null &&
              <Box>
                <div dangerouslySetInnerHTML={{__html: col_3.value}} />
              </Box>
            }
          </Grid>
        </Container>
      </Box>

      <Box as="section">
        <Container sx={{marginTop: 13}}>
          <Carousel data={slideData} />
        </Container>
      </Box>

    </Layout>
  );

}

export default ProductPageTemplate;

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      description
      descriptionHtml
      images {
        id
        localFile {
          childImageSharp {
            full: fixed(width: 400) {
              ...GatsbyImageSharpFixed_withWebp
            }
            fixed: fixed(width: 200) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
    shopifyunstableapi {
      productByHandle(handle: $handle ) {
        col_1: metafield(key: "col_1",namespace: "product") {
          value
        }
        col_2: metafield(key: "col_2",namespace: "product") {
          value
        }
        col_3: metafield(key: "col_3",namespace: "product") {
          value
        }
        icons: metafield(key: "icons",namespace: "product") {
          value
        }
        seo: seo {
          description
          title
        }
      }
    }
    weldingBlanketSlide: file(relativePath: { eq: "welding-use-case.png"}) {
      childImageSharp {
        fluid(maxWidth: 460) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    personalProtectionSlide: file(relativePath: { eq: "ppe-use-case.png"}) {
      childImageSharp {
        fluid(maxWidth: 460) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heatSoundSlide: file(relativePath: { eq: "heat-sound-use-case.png"}) {
      childImageSharp {
        fluid(maxWidth: 460) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
