/** @jsx jsx */
import React from 'react'
import { Flex, Card, Image, jsx, Styled } from 'theme-ui'
import styled from '@emotion/styled'

import Img from 'gatsby-image'

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Button from './button'

import industrial from '../images/fire-blanket-1.png'
import household from '../images/household-fire-blanket.png'
import commercial from '../images/commercial-fire-blanket.png'

const StyledCarousel = styled(Carousel)`
  padding-bottom: 60px;
`
export default class extends React.Component {

  render() {
  
    const slideData = this.props.data;

    return (

        <StyledCarousel
          additionalTransfrom={0}
          arrows={true}
          autoPlaySpeed={3000}
          centerMode={false}
          className=""
          containerClass="container-padding-bottom"
          // customButtonGroup={<CustomButtonGroup />}
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024
              },
              items: 3,
              partialVisibilityGutter: 40
            },
            mobile: {
              breakpoint: {
                max: 600,
                min: 0
              },
              items: 1,
              partialVisibilityGutter: 30
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 600
              },
              items: 2,
              partialVisibilityGutter: 30
            }
          }}
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
        {
          slideData.map((slide, i) => (
            <Card 
              key={i}
              mx={3} 
              sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              width: '90%',
  
              '@media screen and (max-width: 600px)': {
                width: '75%',
                margin: '0 auto'
              }
              }}>
              <Img fluid={slide.image} width={'100%'}/>
              <Flex p={3} sx={{flex: '1', flexDirection: 'column', alignItems: 'center', textAlign: 'center', justifyContent: 'space-between'}}>
                <Styled.h3 sx={{paddingX: 4, marginBottom: 0}}>{slide.title}</Styled.h3>
                <p sx={{ variant: 'text.body'}}>{slide.text}</p> 
                <Button to={slide.link} text="Explore More" sx={{marginTop: '30px'}} />
              </Flex>
            </Card>
          ))
        }
        
        </StyledCarousel>
      )
    }
}
