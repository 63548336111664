import React from 'react'
import { Flex, Box } from 'theme-ui'
import { Container } from './item'
import styled from '@emotion/styled'

import FlameIcon from '../images/icons/flame-solo-icon.svg'

const StyledFlameIcon = styled(FlameIcon)`
  width: 60px;
  height: auto;

  @media screen and (max-width: 768px) {
    width: 40px;
    height: auto;
  }
`

const Seperator = ({ imgSrc, lineColor }) => {
  return (
    <Container>
      <Flex sx={{alignItems: 'center', position: 'relative', paddingY: [4, 13] }}>
          
        <Box sx={{ background: '#fff', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', paddingX: ['10px','20px'] }}>
            <StyledFlameIcon />
        </Box>


        <Box sx={{borderBottom: '7px solid #828382', height: '1px', width: '100%'}} />
      </Flex>
    </Container>
    
  )
}

export default Seperator;